@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: white;
}

button {
  @apply bg-blue-500 rounded hover:bg-blue-700 font-bold py-2 px-4;
  @apply disabled:opacity-30;
}
